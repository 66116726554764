import React, { useState } from "react"


function Talk(props) {
  const [formState, setFormState] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    support: '',
    message: '',
  });

  const inputChangeHandler = event => {
    event.persist()

    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };

  const formSubmitHandler = async event => {
    event.preventDefault();

    const gql = {
      query: `
        mutation SubmitContactForm($name: String!, $email: String!, $phone: String!, $address: String!, $support: String!, $message: String!) {
          contactSubmit(contactInput: {name: $name, email: $email, phone: $phone, address: $address, support:$support, message: $message}) {
            id
          }
        }
      `,
      variables: {
        name: formState.name,
        email: formState.email,
        phone: formState.phone,
        address: formState.address,
        support: formState.support,
        message: formState.message,
      },
    };

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(gql),
    };

    try {
      const response = await fetch('https://finelk.netlify.app/.netlify/functions/api', options);

      const data = await response.json();

      console.log(data);

      setFormState({
        name: '',
        email: '',
        phone: '',
        address:'',
        support: '',
        message: '',
      });
    } catch(error) {
      console.error(error);
    }
  };

  return (
    <div className="keep-in-touch">
      <div className="fine-headline text-uppercase ">
        <p className=''>
          Feel free to contact us
        </p>
        <h2>
          Get in touch
        </h2>
      </div>

      <form action="#" className="container mt-5 w-100 form-keep" onSubmit={formSubmitHandler}>
        <div className="row">
          <div className="w-100 col-lg-6">

            <div className="form-group">
              <input type="text" className="form-control input" name="name"  value={formState.name} onChange={inputChangeHandler}  aria-describedby="nameHelp" placeholder="Your name"/>
            </div>

            <div className="form-group">
              <input type="email" className="form-control input" name="email" value={formState.email} onChange={inputChangeHandler} placeholder="Your email"/>
            </div>

            <div className="form-group">
              <input type="text" className="form-control input" name="phone"  value={formState.phone} onChange={inputChangeHandler}  aria-describedby="phonelHelp" placeholder="Your phone"/>
            </div>

            <div className="form-group mb-lg-0">
              <input type="text" className="form-control input mb-lg-0"  name="address" value={formState.address} onChange={inputChangeHandler} aria-describedby="adressHelp" placeholder="Your address"/>
            </div>

          </div>

          <div className="w-100 col-lg-6">
            <div className="form-group">
              <select className="form-control input" name="support" value={formState.support} onChange={inputChangeHandler} id="exampleFormControlSelect1">
                <option></option>
                <option>Products</option>
                <option>Accessories</option>
                <option>Support</option>
                <option>Others</option>
              </select>
            </div>

            <div className="form-group mb-lg-0">
              <textarea className="form-control pt-lg-0 pb-lg-0 input" name="message"  value={formState.message} onChange={inputChangeHandler} rows="6" placeholder="Your message"/>
            </div>

          </div>

          <div className="row col-lg-12 justify-content-lg-between py-3 px-0">

            <div className="form-check keep-check">
              <div className="custom-control custom-checkbox d-flex align-items-center">
                <input type="checkbox" className="custom-control-input" id="defaultChecked2" checked/>
                <label className="custom-control-label" htmlFor="defaultChecked2">I'am agree to receive all or promotion mail</label>
              </div>
            </div>

            <button type="submit" className="keep-btn">Send</button>
          </div>
        </div>
      </form>
    </div>
  )
}
export default Talk
